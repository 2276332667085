import React, { useState } from 'react';
import './LandingPage.css'; // We'll define basic CSS in a separate file
import LogoWithCompanyNameDark from '../assets/etapay-khwarizm-dark.png'
import Screenshot1 from '../assets/screenshots/1.png'
import Screenshot2 from '../assets/screenshots/2.png'
import Screenshot3 from '../assets/screenshots/3.png'


function LandingPage() {
  // State to track mobile menu open/closed
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="landing-container">
      {/* Navigation / Header */}
      <header className="header">
        <div style={{ display: "flex", height: "8vh", justifyContent: "center", alignItems: "center" }}>
          <img src={LogoWithCompanyNameDark} alt='logo' style={{ height: "100%", width: "auto", objectFit: "contain", marginLeft: -20 }} />
        </div>
        <nav className="nav-bar">
          <button className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
          <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
            <li><a href="#features" onClick={() => setMenuOpen(false)}>Features</a></li>
            <li><a href="#how-it-works" onClick={() => setMenuOpen(false)}>How it works</a></li>
            <li><a href="#about" onClick={() => setMenuOpen(false)}>About</a></li>
            <li><a href="#faq" onClick={() => setMenuOpen(false)}>FAQ</a></li>
            <li><a href="#contact" onClick={() => setMenuOpen(false)}>Contact</a></li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <h1>Your Payment Gateway App</h1>
        <p>Accept payments using only an app.</p>
        <a className="cta-button" href="#features">Learn More</a>
      </section>

      {/* Features Section */}
      <section id="features" className="features-section">
        <h2>Why etaPay?</h2>
        <div className="features-grid">
          <div className="feature-item">
            <span className="icon">⏱️</span>
            <h3>Easy, Low-Cost Setup</h3>
            <p>Go live in minutes without complex coding or hosting worries.</p>
          </div>
          <div className="feature-item">
            <span className="icon">📱</span>
            <h3>In-Person Acceptance</h3>
            <p>Use a QR code for quick and secure face-to-face payments.</p>
          </div>
          <div className="feature-item">
            <span className="icon">🔗</span>
            <h3>Online Payments via Links</h3>
            <p>Send a payment link to your customers in just a few taps.</p>
          </div>
          <div className="feature-item">
            <span className="icon">🧾</span>
            <h3>Generate Receipts</h3>
            <p>Provide professional receipts on the spot—no extra tools needed.</p>
          </div>
          <div className="feature-item">
            <span className="icon">📊</span>
            <h3>View Reports</h3>
            <p>Track transactions, refunds, and statuses all in one place.</p>
          </div>
          <div className="feature-item">
            <span className="icon">🎨</span>
            <h3>Brand Customization</h3>
            <p>Add your own logo and business details to your payment page.</p>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works" className="hiw-section">
        <h2>How It Works</h2>
        <div className="hiw-steps">
          <div className="step">
            <span className="icon">📥</span>
            <p>Download etaPay and create your account</p>
          </div>
          <div className="step">
            <span className="icon">🔒</span>
            <p>Subscribe to enable live payments mode</p>
          </div>
          <div className="step">
            <span className="icon">⚙️</span>
            <p>Select your payment services provider and enter setup details</p>
          </div>
          <div className="step">
            <span className="icon">💳</span>
            <p>Start accepting payments using QR in person or by sending links online</p>
          </div>
        </div>
      </section>

      {/* Placeholder Screenshots */}
      <section className="screenshots-section">
        <h2>etaPay in Action</h2>
        <p>Check out how easy it is to manage payments from a single app.</p>
        <div className="screenshots-grid">
          <img src={Screenshot1} alt='Screenshot1' style={{ height: "700px", width: "auto", objectFit: "contain" }} />
          <img src={Screenshot2} alt='Screenshot2' style={{ height: "700px", width: "auto", objectFit: "contain" }} />
          <img src={Screenshot3} alt='Screenshot3' style={{ height: "700px", width: "auto", objectFit: "contain" }} />
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="about-section">
        <h2>About Us</h2>
        <p>
          We’re a dedicated team focused on simplifying the payment process for
          small and growing businesses. With etaPay, we aim to remove the
          stress of setting up payment gateways and help you get paid faster.
        </p>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <li>
            <strong>Do I need a website?</strong>
            <p>No, etaPay lets you accept payments with just the app—no website required. You can just have a store on Instagram, WhatsApp, or in person and just send a link from etaPay app to accept payments or show a QR code if you're selling in person. </p>
          </li>
          <li>
            <strong>Is there a per-transaction fee?</strong>
            <p>No, we only charge a simple monthly or annual subscription (no hidden fees). Your chosen payment provider will have their own per-transaction fees.</p>
          </li>
        </ul>
      </section>

      {/* Contact / Footer Section */}
      <footer className="footer" id="contact">
        <div className="footer-content">
          <p>
            Questions? Email us at{" "}
            <a href="mailto:support@etapay.me">support@etapay.me</a>
          </p>
          <div className="links">
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
        <p className="copyright">
          &copy; {new Date().getFullYear()} etaPay. All rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default LandingPage;
